<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="location"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.locationInfo,
          valueExpr: 'ID',
          displayExpr: 'Name',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Location" />
        <DxRequiredRule />
      </DxSimpleItem>


      <DxSimpleItem
        data-field="year"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.yearList,
          valueExpr: 'year',
          displayExpr: 'year',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Year" />
        <DxRequiredRule />
      </DxSimpleItem>
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @cell-prepared="onCellPrepared"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multi" />
      <DxExport :enabled="true" />
      <DxColumnFixing :enabled="true" />

      <DxColumn caption="Customer" :fixed="true">
        <DxColumn data-field="custCode" caption="Code" />
        <DxColumn data-field="custName" caption="Name" />
      </DxColumn>

      <DxColumn caption="Item" :fixed="true">
        <DxColumn data-field="itemCode" caption="Code" />
        <DxColumn data-field="itemName" caption="Name" />
      </DxColumn>

      <DxColumn caption="Jan">
        <DxColumn
          data-field="mm01Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm01Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm01Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Feb">
        <DxColumn
          data-field="mm02Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm02Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm02Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Mar">
        <DxColumn
          data-field="mm03Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm03Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm03Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Apr">
        <DxColumn
          data-field="mm04Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm04Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm04Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="May">
        <DxColumn
          data-field="mm05Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm05Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm05Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Jun">
        <DxColumn
          data-field="mm06Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm06Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm06Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Jul">
        <DxColumn
          data-field="mm07Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm07Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm07Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Aug">
        <DxColumn
          data-field="mm08Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm08Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm08Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Sep">
        <DxColumn
          data-field="mm09Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm09Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm09Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Oct">
        <DxColumn
          data-field="mm10Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm10Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm10Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Nov">
        <DxColumn
          data-field="mm11Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm11Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm11Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Dec">
        <DxColumn
          data-field="mm12Qty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm12Usd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="mm12Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

      <DxColumn caption="Total">
        <DxColumn
          data-field="totalQty"
          caption="Quantity"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="totalUsd"
          caption="USD"
          dataType="number"
          format="###,###,###,##0.##"
        />
        <DxColumn
          data-field="totalKrw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0.##"
        />
      </DxColumn>

    </DxDataGrid>
    <DxPopup
      :ref="popupRefName"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="400"
      :height="300"
      title="Excel Load"
    >
      <form
        id="form"
        :ref="formRefName"
        method="post"
        enctype="multipart/form-data"
      >
        <div class="fileuploader-container">
          <div class="dx-field">
            <DxFileUploader
              :ref="fileUploaderRefName"
              name="file"
              select-button-text="Excel 파일 선택"
              upload-mode="useForm"
              accept=".xlsx"
            />
          </div>
        </div>
        <div class="buttonContainer">
          <DxButton
            text="Load"
            icon="upload"
            width="200"
            @click="uploadExcel"
          />
        </div>
      </form>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel,
  DxColumnFixing,
} from "devextreme-vue/data-grid";
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";
import DxPopup from "devextreme-vue/popup";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxButton from "devextreme-vue/button";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import { location } from "../../utils/syscodedata";
import store from "../../store";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxFileUploader,
    DxPopup,
    DxButton,
    DxRequiredRule,
    DxColumnFixing,
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      formData: {},
      gridRefName: "grid",
      dataSource: [],
      yearList: [],
      popupRefName: "popup",
      formRefName: "form",
      fileUploaderRefName: "excelFile",
      locationInfo: location,
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    popup: function () {
      return this.$refs[this.popupRefName].instance;
    },
    file: function () {
      return this.$refs[this.fileUploaderRefName].instance;
    },
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "UBBL"
    );

    const myLocation = store.getters.getLocation;

    if (menuAuth.f1Auth == "false") {
      this.locationInfo = [{ ID: myLocation, Name: myLocation }];
    }

    this.formData.location = this.locationInfo[0].ID;

    dsu.getAxiosYearList().then((res) => {
      this.yearList = res.data.data;
      this.formData.year = new Date().getFullYear().toString();
    });

  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Load",
            icon: "xlsfile",
            stylingMode: "outlined",
            onClick: this.showExcelUpload.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Save",
            icon: "save",
            stylingMode: "outlined",
            onClick: this.save.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Delete",
            icon: "remove",
            stylingMode: "outlined",
            onClick: this.delete.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            icon: "exportxlsx",
            text: "Download",
            stylingMode: "outlined",
            onClick: this.exportToExcel.bind(this),
          },
        }
      );
    },
    refreshData() {
      this.dataSource = [];
      var params = {};
      Object.assign(params, this.formData);

      if (params.year && params.location) {
        dsu
          .getAxiosOBJGet("/api/UBBL/selectSalesLocationForcast", params)
          .then((res) => {
            this.dataSource = res.data.data;
          });
      }
    },
    onValueChanged() {
      this.refreshData();
    },

    save(e) {
      const searchForm = this.$refs[this.searchFormRefName].instance;

      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Year 정보 선택 후 진행 필요");
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      params.salesForcast = JSON.stringify(this.dataSource);
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost("/api/UBBL/updateSalesLocationForcast", params)
        .then((res) => {
          let response = res.data;
          e.component.option("disabled", false);

          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    async delete(e) {
      const searchForm = this.$refs[this.searchFormRefName].instance;
      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Year 정보 선택 후 진행 필요");
        return;
      }
      
      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost("/api/UBBL/deleteSalesLocationForcast", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
            this.refreshData();
          } else {
            fn.notifyError(response.msg);
          }

          e.component.option("disabled", false);
        });
    },
    showExcelUpload() {
      this.file.reset();

      const searchForm = this.$refs[this.searchFormRefName].instance;

      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Year 정보 선택 후 진행 필요");
        return;
      }

      this.popup.show();
    },
    setTotal() {
      this.dataSource = this.dataSource.map((item) => {
        return {
          ...item,
          totalQty:
            (item.mm01Qty +
            item.mm02Qty +
            item.mm03Qty +
            item.mm04Qty +
            item.mm05Qty +
            item.mm06Qty +
            item.mm07Qty +
            item.mm08Qty +
            item.mm09Qty +
            item.mm10Qty +
            item.mm11Qty +
            item.mm12Qty),
          totalUsd:
            (item.mm01Usd +
            item.mm02Usd +
            item.mm03Usd +
            item.mm04Usd +
            item.mm05Usd +
            item.mm06Usd +
            item.mm07Usd +
            item.mm08Usd +
            item.mm09Usd +
            item.mm10Usd +
            item.mm11Usd +
            item.mm12Usd),
          totalKrw:
            (item.mm01Krw +
            item.mm02Krw +
            item.mm03Krw +
            item.mm04Krw +
            item.mm05Krw +
            item.mm06Krw +
            item.mm07Krw +
            item.mm08Krw +
            item.mm09Krw +
            item.mm10Krw +
            item.mm11Krw +
            item.mm12Krw),
        };
      });
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    },
    uploadExcel(e) {
      var fileObj = this.file.option("value");

      if (fileObj.length == 0) {
        fn.notifyError("파일 미 선택");
        return;
      }

      if (fileObj[0].size > 104857600) {
        fn.notifyError("최대용량 초과(100mb)");
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      const form = this.$refs[this.formRefName];
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPostMultiPart(
          "/api/UBBL/uploadExcelSalesLocationForcast",
          params,
          form
        )
        .then((res) => {
          e.component.option("disabled", false);
          let response = res.data;
          if (response.result == "SUCCESS") {
            this.dataSource = response.data;
            this.setTotal();
          } else {
            fn.alert(response.msg);
          }
          this.popup.hide();
        });
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onCellPrepared(e) {
      if (e.rowType == "data") {
        var dataField = e.column.dataField;

        if (dataField.includes("Qty")) {
          e.cellElement.bgColor = "#f0f0f0";
        }
      }
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 250px);
}

.fileuploader-container {
  border: 1px solid #d3d3d3;
  text-align: center;
  height: 135px;
}
.buttonContainer {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.dx-field-label {
  text-align: right;
}
</style>
