<template>
  <tableau-viz
    v-if="showTableau"
    id="tableauViz"
    src="https://prod-apnortheast-a.online.tableau.com/t/seowoosoft/views/n_Main/mobile-Fund"
    :token="tableauToken"
    :hide-tabs="true"
    :hide-edit-button="true"
    :hide-edit-in-desktop-button="true"
  >
  </tableau-viz>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  data() {
    return {
      showTableau: false,
      tableauToken: "",
    };
  },
  mounted() {
    let token = store.getters.getToken;
        let paramsTableau = {
          token: token,
          userId: store.getters.getUserId,
          updateExpireTime: "N",
        };
        axios
          .post("/loginTableau", "", {
            headers: { "Content-Type": "application/json; charset=UTF-8" },
            params: paramsTableau,
          })
          .then((res) => {
            this.tableauToken = res.data.msg;
            this.showTableau = true;
    });
  }
};
</script>

<style lang="scss"></style>
