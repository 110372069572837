<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="location"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.locationInfo,
          valueExpr: 'ID',
          displayExpr: 'Name',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Location" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="dt"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',
          onValueChanged: onValueChanged,
          calendarOptions: {
            cellTemplate: 'cellTemplate1',
          },
        }"
      >
        <DxLabel text="Date" />
        <DxRequiredRule />
      
      </DxSimpleItem>
      <DxGroupItem :colCount="2">
        <DxButtonItem
          horizontal-alignment="center"
          vertical-alignment="center"
          :buttonOptions="{
            text: 'Bank',
            icon: 'money',
            onClick: showUploadDialog,
          }"
        />
        <DxButtonItem
          horizontal-alignment="center"
          vertical-alignment="center"
          :buttonOptions="{
            text: 'Details',
            icon: 'detailslayout',
            onClick: showDetails,
          }"
        />
      </DxGroupItem>
      <template #cellTemplate1="{ data: cell }">
        <span :class="getCellCssClass(cell)">
            {{ cell.text }}
        </span>
      </template>
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <tableau-viz
      v-if="showTableau"
      class="tabCon"
      id="tableauViz"
      src="https://prod-apnortheast-a.online.tableau.com/t/seowoosoft/views/n__17140274471750/Status"
      :token="tableauToken"
      :hide-tabs="true"
      :hide-edit-button="true"
      :hide-edit-in-desktop-button="true"
    >
      <custom-parameter name="location" :value="location"></custom-parameter>
      <custom-parameter name="dt2" :value="dt"></custom-parameter>
    </tableau-viz>
  </div>

  <div class="content-block dx-card responsive-paddings" v-if="selectedFileId2">
    <div class="dx-card responsive-paddings">
      <a :v-show="selectedFileId2" class="dw" :href="getFileURL(this.selectedFileId2)" :download="selectedFileName2"> {{ selectedFileName2}}</a>
    </div>
  </div>
  <div class="content-block dx-card responsive-paddings" v-if="selectedFileId">
    <div class="dx-card responsive-paddings">
      <DxScrollView id="scrollView" ref="scrollViewRefName" direction="both">
        <img
          class="imageBankBalance"
          :src="getFileURL(selectedFileId)"
        />
      </DxScrollView>
    </div>
  </div>

  <DxPopup
    :ref="popupRefName"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-title="true"
    width="80%"
    height="90%"
    title="Bank Balance"
  >
    <div class="content-block dx-card responsive-paddings">
      <div class="pTitle">
        ▶ File
      </div>
      
      <DxFileUploader
        :ref="fileUploaderRefName2"
        width="400"
        name="file"
        @beforeSend="onBeforeSend($event, 'BANK_BALANCE_FILE')"
        @uploaded="onUploaded($event, 'BANK_BALANCE_FILE')"
        upload-url="/api/UFSL/upload"
        select-button-text="Select File"
        :max-file-size="9600000"
      />
      <a :v-show="selectedFileId2" class="dw" :href="getFileURL(this.selectedFileId2)" :download="selectedFileName2"> {{ selectedFileName2}}</a>
      <DxButton
        class="btn"
        v-show="selectedFileId2"  
        :width="120"
        :height="30"
        text="Delete"
        type="normal"
        styling-mode="contained"
        @click="onClickDeleteFile($event, 'BANK_BALANCE_FILE')"
      />
    </div>

    <div class="content-block dx-card responsive-paddings">
      <div class="pTitle">
        ▶ Image
      </div>

      <DxFileUploader
        :ref="fileUploaderRefName"
        width="400"
        name="file"
        @beforeSend="onBeforeSend($event, 'BANK_BALANCE_IMAGE')"
        @uploaded="onUploaded($event, 'BANK_BALANCE_IMAGE')"
        upload-url="/api/UFSL/upload"
        select-button-text="Select Image"
        accept="image/*"
        :max-file-size="4800000"
      />
      <DxButton
        v-show="selectedFileId"
        class="fileDeleteBtn"
        :width="135"
        :height="30"
        text="Delete"
        type="normal"
        styling-mode="contained"
        @click="onClickDeleteFile($event, 'BANK_BALANCE_IMAGE')"
      />
      <div class="imgFrame">
        <DxScrollView id="scrollView" ref="scrollViewRefName" direction="both">
          <img v-show="selectedFileId"
            class="imageBankBalance"
            :src="getFileURL(selectedFileId)"
          />
        </DxScrollView>
      </div>
    </div>
  </DxPopup>
    <DxPopup
    :ref="popupDetailsRefName"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-title="true"
    width="90%"
    height="90%"
    title="Details">
    <DxScrollView id="scrollView" ref="scrollViewRefName" direction="both">
      <FundFlowDetail :ref="detailsRefName" :location="this.formData.location" :dt="this.formData.dt"/>
    </DxScrollView>
  </DxPopup>

</template>
<script>
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
  DxButtonItem,
  DxGroupItem
} from "devextreme-vue/form";
import { locationFundFlow } from "../../utils/syscodedata";
import axios from "axios";
import store from "../../store";
import DxPopup from "devextreme-vue/popup";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxButton from "devextreme-vue/button";
import fn from "../../utils/function";
import dsu from "../../utils/dataSourceUtils";
import DxScrollView from "devextreme-vue/scroll-view";
import FundFlowDetail from "../_common/FUND_FLOW_DETAIL"

var toDay = new Date();

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxButtonItem,
    DxPopup,
    DxFileUploader,
    DxButton,
    DxScrollView,
    DxGroupItem,
    FundFlowDetail
  },

  data() {
    return {
      popupRefName: "popup",
      searchFormRefName: "searchForm",
      formData: { dt: toDay },
      locationInfo: locationFundFlow,
      showTableau: false,
      tableauToken: "",
      location: "",
      dt: "",
      fileUploaderRefName: "fileUploader",
      selectedFileId: "",
      fileUploaderRefName2: "fileUploader2",
      selectedFileId2: "",
      selectedFileName2: "",
      scrollViewRefName: "scrollView",
      bankBalanceList: [],
      popupDetailsRefName: "popupDetails",
      detailsRefName: "details"
    };
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "UFSL"
    );
    const myLocation = store.getters.getLocation;
    if (menuAuth.f1Auth == "false") {
      this.locationInfo = [{ ID: myLocation, Name: myLocation }];
    } else {
      this.locationInfo = JSON.parse(JSON.stringify(locationFundFlow));
      this.locationInfo.push({ ID: 'ALL', Name: 'ALL' });
    }
    this.formData.location = this.locationInfo[0].ID;
  },
  computed: {
    popup: function () {
      return this.$refs[this.popupRefName].instance;
    },
    file: function () {
      return this.$refs[this.fileUploaderRefName].instance;
    },
    file2: function () {
      return this.$refs[this.fileUploaderRefName2].instance;
    },
    popupDetails: function () {
      return this.$refs[this.popupDetailsRefName].instance;
    }
  },

  methods: {
    getCellCssClass({ date, view }) {
      let cssClass = '';
      if (view === 'month') {
        console.log(date.getMonth());
        if (!date) {
          cssClass = 'week-number';
        } else {
          this.bankBalanceList.forEach((item) => {
            if (date.getDate() === item.day && (date.getMonth() + 1) === item.month && date.getFullYear() === item.year) {
              cssClass = 'existsImage';
            }
          });
        }
      }
      return cssClass;
    },
    refreshBankBalanceList() {
      this.bankBalanceList = [];
      var params = {};
      params.location = this.formData.location;

      dsu
      .getAxiosOBJGet("/api/UFSL/selectFundFlowBankBalanceList", params)
      .then((res) => {
        let response = res.data;
        if (response.result == "SUCCESS") {
          if (response.data.length > 0) {
            this.bankBalanceList = response.data;
            
          }
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    showDetails() {
      if(this.location == 'ALL'){
        fn.notifyError("개별 Location 선택 필요");
        return;
      }
      this.$refs.details.refreshData();
      this.popupDetails.show();
    },
    showUploadDialog() {
      if(this.location == 'ALL'){
        fn.notifyError("개별 Location 선택 필요");
        return;
      }

      this.selectedFileId = "";
      this.selectedFileId2 = "";
      this.selectedFileName2 = "";
      this.file.reset();
      this.file2.reset();
      const searchForm = this.$refs[this.searchFormRefName].instance;
      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Location, Date 선택 필요");
        return;
      }

      this.getBankBalance();
      this.popup.show();
    },
    getBankBalance() {
      this.selectedFileId = "";
      var params = {};
      Object.assign(params, this.formData);
      params.dt = dsu.getFormatDate(params.dt);

      params.type = "BANK_BALANCE_IMAGE";
      dsu
        .getAxiosOBJGet("/api/UFSL/selectFundFlowBankBalance", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            if (response.data.length > 0) {
              this.selectedFileId = response.data[0].fileId;
            }
          } else {
            fn.notifyError(response.msg);
          }
        });

      params.type = "BANK_BALANCE_FILE";
      dsu
        .getAxiosOBJGet("/api/UFSL/selectFundFlowBankBalance", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            if (response.data.length > 0) {
              this.selectedFileId2 = response.data[0].fileId;
              this.selectedFileName2 = response.data[0].fileName;
            }
          } else {
            fn.notifyError(response.msg);
          }
        });  
    },
    onBeforeSend(e, type) {
      const headers = dsu.getHeaders();
      e.request.setRequestHeader("token", headers.token);
      e.request.setRequestHeader("userId", headers.userId);
      e.request.setRequestHeader("fileType", type);
    },
    onUploaded(e, type) {
      const response = JSON.parse(e.request.responseText);
      if (response.result == "SUCCESS") {
        var params = {};
        Object.assign(params, this.formData);
        params.dt = dsu.getFormatDate(params.dt);
        params.fileId = response.msg;

        if (type == "BANK_BALANCE_IMAGE"){
          this.selectedFileId = response.msg;
        }
        else if (type == "BANK_BALANCE_FILE"){
          this.selectedFileId2 = response.msg;
          this.selectedFileName2 = e.file.name;
        }
        params.type = type;

        dsu
          .getAxiosOBJPost("/api/UFSL/updateFundFlowBankBalance", params)
          .then((res) => {
            let response = res.data;
            this.refreshData();
            if (response.result == "SUCCESS") {
              fn.notifySuccess(response.msg);
            } else {
              fn.notifyError(response.msg);
              e.cancel = true;
            }
          });
      } else {
        alert((e.message = "Upload Fail"));
      }
    },
    onValueChanged() {
      this.refreshData();
    },
    getFileURL(fileId) {
      return encodeURI("/api/UFSL/downloadFile?fileId=" + fileId);
    },
    async onClickDeleteFile(e, type) {
      // if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
      //   return;
      // }
      if ((await fn.confirm("Are you sure you want to delete?")) == false) {
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      params.dt = dsu.getFormatDate(params.dt);
      params.type = type;
      e.component.option("disabled", true);
      dsu
        .getAxiosOBJPost("/api/UFSL/deleteFundFlowBankBalance", params)
        .then((res) => {
          e.component.option("disabled", false);
          let response = res.data;
          this.refreshData();
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
            if (type == 'BANK_BALANCE_IMAGE') {
              this.selectedFileId = "";
              this.file.reset();
            } else if (type == 'BANK_BALANCE_FILE') {
              this.selectedFileId2 = "";
              this.selectedFileName2 = "";
              this.file2.reset();
            }
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    refreshData() {
      //검색 조건 선택 validation
      var params = {};
      this.showTableau = false;
      Object.assign(params, this.formData);
      params.dt = dsu.getFormatDate(params.dt);
      if (params.location && params.dt) {
        //tableu token 가져오기
        this.getBankBalance();
        this.refreshBankBalanceList();
        let token = store.getters.getToken;
        let paramsTableau = {
          token: token,
          userId: store.getters.getUserId,
          updateExpireTime: "N",
        };
        axios
          .post("/loginTableau", "", {
            headers: { "Content-Type": "application/json; charset=UTF-8" },
            params: paramsTableau,
          })
          .then((res) => {
            this.tableauToken = res.data.msg;
            this.location = params.location;
            this.dt = params.dt;
            this.showTableau = true;
          });
      }
    },
  },
};
</script>

<style lang="scss">
  .imgFrame {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border: 2px solid #ccc;
    background-color: #eee;
    text-align: left;
    height: calc((100vh * 0.8) - 500px);
    width: 100%;
    .imageBankBalance {
       width: 100%;
    }
  }

  .dx-calendar-cell:not(.dx-calendar-other-month) .existsImage {
    color: #ff0000;
    font-weight: bold;
  }

  .pTitle {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .fileDeleteBtn{
    margin-left: 10px;
  }

 .btn{
  margin-left: 10px;
  margin-right: 10px;
 }
 .dw{
  margin-left: 15px;
  margin-right: 20px;
 }

.tabCon{
  width: 1320px !important;
  height: 1630px !important;
}
</style>
