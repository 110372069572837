<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="location"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.locationInfo,
          valueExpr: 'ID',
          displayExpr: 'Name',
          onValueChanged: onValueChanged,
        }"
      >
      <DxLabel text="Location" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="year"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.yearList,
          valueExpr: 'year',
          displayExpr: 'year',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Year" />
        <DxRequiredRule />
      </DxSimpleItem>
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      @cell-dbl-click="onCellDblCkick"
      @cell-prepared="onCellPrepared"
    >
      <DxScrolling mode="infinite" />

      <DxColumn
        data-field="month"
        caption="Month"
        :width="100"
        Alignment="center"
      >
        <DxLookup
          :data-source="monthList"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn>
      <DxColumn data-field="d01" caption="D01" Alignment="center" width="65" />
      <DxColumn data-field="d02" caption="D02" Alignment="center" width="65" />
      <DxColumn data-field="d03" caption="D03" Alignment="center" width="65" />
      <DxColumn data-field="d04" caption="D04" Alignment="center" width="65" />
      <DxColumn data-field="d05" caption="D05" Alignment="center" width="65" />
      <DxColumn data-field="d06" caption="D06" Alignment="center" width="65" />
      <DxColumn data-field="d07" caption="D07" Alignment="center" width="65" />
      <DxColumn data-field="d08" caption="D08" Alignment="center" width="65" />
      <DxColumn data-field="d09" caption="D09" Alignment="center" width="65" />
      <DxColumn data-field="d10" caption="D10" Alignment="center" width="65" />
      <DxColumn data-field="d11" caption="D11" Alignment="center" width="65" />
      <DxColumn data-field="d12" caption="D12" Alignment="center" width="65" />
      <DxColumn data-field="d13" caption="D13" Alignment="center" width="65" />
      <DxColumn data-field="d14" caption="D14" Alignment="center" width="65" />
      <DxColumn data-field="d15" caption="D15" Alignment="center" width="65" />
      <DxColumn data-field="d16" caption="D16" Alignment="center" width="65" />
      <DxColumn data-field="d17" caption="D17" Alignment="center" width="65" />
      <DxColumn data-field="d18" caption="D18" Alignment="center" width="65" />
      <DxColumn data-field="d19" caption="D19" Alignment="center" width="65" />
      <DxColumn data-field="d20" caption="D20" Alignment="center" width="65" />
      <DxColumn data-field="d21" caption="D21" Alignment="center" width="65" />
      <DxColumn data-field="d22" caption="D22" Alignment="center" width="65" />
      <DxColumn data-field="d23" caption="D23" Alignment="center" width="65" />
      <DxColumn data-field="d24" caption="D24" Alignment="center" width="65" />
      <DxColumn data-field="d25" caption="D25" Alignment="center" width="65" />
      <DxColumn data-field="d26" caption="D26" Alignment="center" width="65" />
      <DxColumn data-field="d27" caption="D27" Alignment="center" width="65" />
      <DxColumn data-field="d28" caption="D28" Alignment="center" width="65" />
      <DxColumn data-field="d29" caption="D29" Alignment="center" width="65" />
      <DxColumn data-field="d30" caption="D30" Alignment="center" width="65" />
      <DxColumn data-field="d31" caption="D31" Alignment="center" width="65" />
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxLookup,
} from "devextreme-vue/data-grid";
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";
import { locationFundFlow, Months } from "../../utils/syscodedata";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import store from "../../store";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxLookup,
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      formData: {},
      gridRefName: "grid",
      dataSource: [],
      locationInfo: locationFundFlow,
      yearList: [],
      monthList: Months,
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "ULMC"
    );

    const myLocation = store.getters.getLocation;

    if (menuAuth.f1Auth == "false") {
      this.locationInfo = [{ ID: myLocation, Name: myLocation }];
    }

    this.formData.location = this.locationInfo[0].ID;

    dsu.getAxiosYearList().then((res) => {
      this.yearList = res.data.data;
      this.formData.year = new Date().getFullYear().toString();
    });
  },
  methods: {
    refreshData() {
      this.dataSource = [];
      var params = {};
      Object.assign(params, this.formData);

      if (params.year && params.location) {
        dsu
          .getAxiosOBJGet("/api/ULMC/selectFundFlowClose", params)
          .then((res) => {
            let response = res.data;
            if (res.data.result == "SUCCESS") {
              this.dataSource = response.data;
            } else {
              fn.notifyError(response.msg);
            }
          });
      }
    },
    onValueChanged() {
      this.refreshData();
    },
    onCellPrepared(e) {
      var dataField = e.column.dataField;
      if (e.rowType == "data" && dataField.substring(0, 1) == "d") {
        var data = e.data[dataField];
        if (data == "Y") {
          e.cellElement.bgColor = "#fca39d";
        } else if (data == "N") {
          e.cellElement.bgColor = "#a8fc9d";
          e.cellElement.style.color = "#bdbdbd";
        } else {
          e.cellElement.bgColor = "#e6e8e6";
        }
      }
    },
    async onCellDblCkick(e) {
      let dataField = "";
      let rowType = e.rowType;

      if (rowType == "data") {
        dataField = e.column.dataField;
        let data = e.data[dataField];
        if (dataField.substring(0, 1) == "d" && data) {
          let year = this.formData.year;
          let month = String(e.data.month).padStart(2, "0");
          let day = dataField.substring(1, 3);
          let date = year + "년 " + month + "월 " + day + "일";
          let qStr = data == "Y" ? "N 으로" : "Y 로";

          if (
            (await fn.confirm(
              date + " 의 마감 상태를 " + qStr + " 변경 하시겠습니까?"
            )) == true
          ) {
            const searchForm = this.$refs[this.searchFormRefName].instance;
            let validationResult = searchForm.validate();
            if (validationResult.status != "valid") {
              fn.notifyError("Location, Year 정보 선택 후 진행 필요");
              return;
            }

            var params = {};
            params.location = this.formData.location;
            params.dt = year + month + day;
            params.close = data == "Y" ? "N" : "Y";

            e.component.option("disabled", true);
            dsu
              .getAxiosOBJPost("/api/ULMC/updateFundFlowClose", params)
              .then((res) => {
                let response = res.data;
                e.component.option("disabled", false);
                if (response.result == "SUCCESS") {
                  fn.notifySuccess(response.msg);
                  this.refreshData();
                } else {
                  fn.notifyError(response.msg);
                }
              });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 350px);
}
</style>
