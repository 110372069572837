<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="location"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.locationInfo,
          valueExpr: 'ID',
          displayExpr: 'Name',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Location" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="year"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.yearList,
          valueExpr: 'year',
          displayExpr: 'year',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Year" />
        <DxRequiredRule />
      </DxSimpleItem>
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @cell-prepared="onCellPrepared"
    >
      <DxToolbar>
        <DxItem location="before">
          <template #default>
            <div class="dx-field">
              <div class="dx-field-label">Base Currency</div>
              <div class="dx-field-value">
                <DxSelectBox
                  :data-source="currencyList"
                  v-model:value="baseCurrency"
                  display-expr="currencyNation"
                  value-expr="currency"
                />
              </div>
            </div>
          </template>
        </DxItem>

        <DxItem location="before">
          <template #default>
            <div class="dx-field">
              <div class="dx-field-label">Base Exchange Rate</div>
              <div class="dx-field-value">
                <DxNumberBox
                  v-model:value="baseExchangeRate"
                  format="###,###,###,##0"
                  @valueChanged="onValueChangedExchangeRate"
                  :min="0"
                />
              </div>
            </div>
          </template>
        </DxItem>
      </DxToolbar>

      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multi" />
      <DxExport :enabled="true" />
      <DxColumnFixing :enabled="true" />

      <DxColumn caption="Account" :fixed="true">
        <DxColumn data-field="acctCode" caption="Code" alignment="center" />
        <DxColumn data-field="acctDescKor" caption="Kor" />
        <DxColumn data-field="acctDescEng" caption="Eng" />
      </DxColumn>

      <DxColumn caption="Jan">
        <DxColumn
          data-field="mm01BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm01Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Feb">
        <DxColumn
          data-field="mm02BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm02Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Mar">
        <DxColumn
          data-field="mm03BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm03Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Apr">
        <DxColumn
          data-field="mm04BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm04Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="May">
        <DxColumn
          data-field="mm05BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm05Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Jun">
        <DxColumn
          data-field="mm06BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm06Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Jul">
        <DxColumn
          data-field="mm07BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm07Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Aug">
        <DxColumn
          data-field="mm08BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm08Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Sep">
        <DxColumn
          data-field="mm09BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm09Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Oct">
        <DxColumn
          data-field="mm10BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm10Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Nov">
        <DxColumn
          data-field="mm11BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm11Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Dec">
        <DxColumn
          data-field="mm12BaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="mm12Krw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>

      <DxColumn caption="Total">
        <DxColumn
          data-field="totalBaseCurrency"
          :caption="this.baseCurrency"
          dataType="number"
          format="###,###,###,##0"
        />
        <DxColumn
          data-field="totalKrw"
          caption="KRW"
          dataType="number"
          format="###,###,###,##0"
        />
      </DxColumn>
    </DxDataGrid>
    <DxPopup
      :ref="popupRefName"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="400"
      :height="300"
      title="Excel Load"
    >
      <form
        id="form"
        :ref="formRefName"
        method="post"
        enctype="multipart/form-data"
      >
        <div class="fileuploader-container">
          <div class="dx-field">
            <DxFileUploader
              :ref="fileUploaderRefName"
              name="file"
              select-button-text="Excel 파일 선택"
              upload-mode="useForm"
              accept=".xlsx"
            />
          </div>
        </div>
        <div class="buttonContainer">
          <DxButton
            text="Load"
            icon="upload"
            width="200"
            @click="uploadExcel"
          />
        </div>
      </form>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel,
  DxToolbar,
  DxItem,
  DxColumnFixing,
} from "devextreme-vue/data-grid";
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxPopup from "devextreme-vue/popup";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxButton from "devextreme-vue/button";
import { locationFundFlow } from "../../utils/syscodedata";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import store from "../../store";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxFileUploader,
    DxPopup,
    DxButton,
    DxRequiredRule,
    DxToolbar,
    DxItem,
    DxNumberBox,
    DxSelectBox,
    DxColumnFixing,
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      formData: {},
      gridRefName: "grid",
      dataSource: [],
      locationInfo: locationFundFlow,
      yearList: [],
      popupRefName: "popup",
      formRefName: "form",
      fileUploaderRefName: "excelFile",
      baseCurrency: "",
      baseExchangeRate: 0,
      currencyList: [],
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    popup: function () {
      return this.$refs[this.popupRefName].instance;
    },
    file: function () {
      return this.$refs[this.fileUploaderRefName].instance;
    },
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "ULBA"
    );

    const myLocation = store.getters.getLocation;
    if (menuAuth.f1Auth == "false") {
      this.locationInfo = [{ ID: myLocation, Name: myLocation }];
    }
    this.formData.location = this.locationInfo[0].ID;

    dsu.getAxiosYearList().then((res) => {
      this.yearList = res.data.data;
      this.formData.year = new Date().getFullYear().toString();
    });

    dsu.getAxiosCurrencyList().then((res) => {
      this.currencyList = res.data.data;
      this.currencyList.unshift({
        currency: "KRW",
        currencyNation: "KRW (Korea)",
      });
    });
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Load",
            icon: "xlsfile",
            stylingMode: "outlined",
            onClick: this.showExcelUpload.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Save",
            icon: "save",
            stylingMode: "outlined",
            onClick: this.save.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Delete",
            icon: "remove",
            stylingMode: "outlined",
            onClick: this.delete.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            icon: "exportxlsx",
            text: "Download",
            stylingMode: "outlined",
            onClick: this.exportToExcel.bind(this),
          },
        }
      );
    },
    refreshData() {
      this.dataSource = [];
      var params = {};
      Object.assign(params, this.formData);

      if (params.year && params.location) {
        dsu
          .getAxiosOBJGet("/api/ULBA/selectBudgetAccount", params)
          .then((res) => {
            this.dataSource = res.data.data;
            if (this.dataSource.length > 0) {
              this.baseCurrency = res.data.data[0].baseCurrency;
              this.baseExchangeRate = res.data.data[0].baseExchangeRate;
            } else {
              this.baseCurrency = "";
              this.baseExchangeRate = 0;
            }
          });
      }
    },
    onValueChanged() {
      this.refreshData();
    },

    save(e) {
      const searchForm = this.$refs[this.searchFormRefName].instance;

      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Location, Year 정보 선택 후 진행 필요");
        return;
      }

      if (!this.baseCurrency) {
        fn.notifyError("Base Currency 정보 입력 후 진행 필요");
        return;
      }

      if (!this.baseExchangeRate) {
        fn.notifyError("Base Exchange Rate 정보 입력 후 진행 필요");
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      params.baseCurrency = this.baseCurrency;
      params.baseExchangeRate = this.baseExchangeRate;

      params.budgetAccount = JSON.stringify(this.dataSource);
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost("/api/ULBA/updateBudgetAccount", params)
        .then((res) => {
          let response = res.data;
          e.component.option("disabled", false);

          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    async delete(e) {
      const searchForm = this.$refs[this.searchFormRefName].instance;
      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Location, Year 정보 선택 후 진행 필요");
        return;
      }

      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost("/api/ULBA/deleteBudgetAccount", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
            this.refreshData();
          } else {
            fn.notifyError(response.msg);
          }

          e.component.option("disabled", false);
        });
    },
    showExcelUpload() {
      this.file.reset();

      const searchForm = this.$refs[this.searchFormRefName].instance;

      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("Location, Year 정보 선택 후 진행 필요");
        return;
      }

      this.popup.show();
    },
    onValueChangedExchangeRate() {
      this.dataSource = this.dataSource.map((item) => {
        return {
          ...item,
          mm01Krw: item.mm01BaseCurrency * this.baseExchangeRate,
          mm02Krw: item.mm02BaseCurrency * this.baseExchangeRate,
          mm03Krw: item.mm03BaseCurrency * this.baseExchangeRate,
          mm04Krw: item.mm04BaseCurrency * this.baseExchangeRate,
          mm05Krw: item.mm05BaseCurrency * this.baseExchangeRate,
          mm06Krw: item.mm06BaseCurrency * this.baseExchangeRate,
          mm07Krw: item.mm07BaseCurrency * this.baseExchangeRate,
          mm08Krw: item.mm08BaseCurrency * this.baseExchangeRate,
          mm09Krw: item.mm09BaseCurrency * this.baseExchangeRate,
          mm10Krw: item.mm10BaseCurrency * this.baseExchangeRate,
          mm11Krw: item.mm11BaseCurrency * this.baseExchangeRate,
          mm12Krw: item.mm12BaseCurrency * this.baseExchangeRate,
          totalBaseCurrency:
            item.mm01BaseCurrency +
            item.mm02BaseCurrency +
            item.mm03BaseCurrency +
            item.mm04BaseCurrency +
            item.mm05BaseCurrency +
            item.mm06BaseCurrency +
            item.mm07BaseCurrency +
            item.mm08BaseCurrency +
            item.mm09BaseCurrency +
            item.mm10BaseCurrency +
            item.mm11BaseCurrency +
            item.mm12BaseCurrency,
          totalKrw:
            (item.mm01BaseCurrency +
              item.mm02BaseCurrency +
              item.mm03BaseCurrency +
              item.mm04BaseCurrency +
              item.mm05BaseCurrency +
              item.mm06BaseCurrency +
              item.mm07BaseCurrency +
              item.mm08BaseCurrency +
              item.mm09BaseCurrency +
              item.mm10BaseCurrency +
              item.mm11BaseCurrency +
              item.mm12BaseCurrency) *
            this.baseExchangeRate,
        };
      });
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    },
    uploadExcel(e) {
      var fileObj = this.file.option("value");

      if (fileObj.length == 0) {
        fn.notifyError("파일 미 선택");
        return;
      }

      if (fileObj[0].size > 104857600) {
        fn.notifyError("최대용량 초과(100mb)");
        return;
      }

      var params = {};
      Object.assign(params, this.formData);
      const form = this.$refs[this.formRefName];
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPostMultiPart(
          "/api/ULBA/uploadExcelBudgetAccount",
          params,
          form
        )
        .then((res) => {
          e.component.option("disabled", false);
          let response = res.data;
          if (response.result == "SUCCESS") {
            this.dataSource = response.data;
            this.onValueChangedExchangeRate();
          } else {
            fn.alert(response.msg);
          }
          this.popup.hide();
        });
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onCellPrepared(e) {
      if (e.rowType == "data") {
        var dataField = e.column.dataField;

        if (dataField.includes("BaseCurrency")) {
          e.cellElement.bgColor = "#f0f0f0";
        }
      }
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 250px);
}

.fileuploader-container {
  border: 1px solid #d3d3d3;
  text-align: center;
  height: 135px;
}
.buttonContainer {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
.dx-field-label {
  text-align: right;
}
</style>
