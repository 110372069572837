<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData">
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4"/>

      <DxSimpleItem data-field="factoryCode"
                    editor-type="dxSelectBox"
                    :editor-options="{dataSource: this.factoryInfo,
                                      valueExpr: 'factoryCode',
                                      displayExpr: 'factoryName',
                                      onValueChanged: onValueChangedFactoryCode}">
                    <DxLabel text="Factory"/>
                    <DxRequiredRule/>
      </DxSimpleItem>

      <DxSimpleItem data-field="id"
                    editor-type="dxSelectBox"
                    :editor-options="{dataSource: this.checkListInfo,
                                      valueExpr: 'id',
                                      displayExpr: 'name',
                                      onValueChanged: onValueChangedCheckListId}">
                    <DxLabel text="Check List"/>
                    <DxRequiredRule/>
      </DxSimpleItem>

      <DxSimpleItem data-field="cat1"
                    editor-type="dxSelectBox"
                    :editor-options="{dataSource: this.cat1List,
                                      valueExpr: 'category',
                                      displayExpr: 'category',
                                      searchEnabled: true,
                                      onValueChanged: onValueChangedCat1}">
                    <DxLabel :text="catNames.cat1Name"/>
                    <DxRequiredRule/>
      </DxSimpleItem>

      <DxSimpleItem data-field="cat2"
                    editor-type="dxSelectBox"
                    :editor-options="{dataSource: this.cat2List,
                          valueExpr: 'category',
                          displayExpr: 'category',
                          searchEnabled: true,
                          onValueChanged: onValueChangedCat2}">
                    <DxLabel :text="catNames.cat2Name"/>
                    <DxRequiredRule/>
      </DxSimpleItem>

      <DxSimpleItem data-field="cat3"
                    editor-type="dxTextBox"
                    :editor-options="{maxLength: '30'}">
                    <DxLabel :text="catNames.cat3Name"/>
                    <DxRequiredRule/>
      </DxSimpleItem>

      <DxSimpleItem data-field="regDate"
                    editor-type="dxDateBox"
                    :editor-options="{displayFormat: 'yyyy/MM/dd',
                                     value: new Date()}">
                    <DxLabel text="Date"/>
                    <DxRequiredRule/>
      </DxSimpleItem>

      <DxButtonItem horizontal-alignment="center"
                    vertical-alignment="center"
                    :buttonOptions="{text: 'Load',
                                     icon: 'tableproperties',
                                     onClick: loadCheckList}"/>
                                     
      

    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
      <CHECK_LIST_DATA :ref="checkListDataRefName"
                       :selectedCheckListData="selectedCheckListData"
                       :isEditingMode="true"/>
  </div>
</template>

<script>
import {DxForm,
        DxColCountByScreen,
        DxSimpleItem,
        DxButtonItem,
        DxLabel,
        DxRequiredRule} from "devextreme-vue/form";
import CHECK_LIST_DATA from '../../views/_common/CHECK_LIST_DATA';
import dsu from "../../utils/dataSourceUtils";

  export default {
    components: {
      DxForm,
      DxColCountByScreen,
      DxSimpleItem,
      DxButtonItem,
      DxLabel,
      DxRequiredRule,
      CHECK_LIST_DATA
    },
    data() {
      return {
        searchFormRefName: 'searchForm',
        formData: {},
        factoryInfo: [],
        checkListInfo: [],
        catNames: {},
        cat1List: [],
        cat2List: [],
        selectedCheckListData: {},
        checkListDataRefName: 'checkListData',
        noUsedParameter: false
      };
    },
    mounted(){
      dsu.getAxiosFactoryInfo().then(res => {this.factoryInfo = res.data.data});
    
      if(this.$route.query.factoryCode){
        this.noUsedParameter = true;
        this.formData.factoryCode = this.$route.query.factoryCode;
        Object.assign(this.selectedCheckListData, this.$route.query);
      }
    },
    methods: {
      onValueChangedFactoryCode(e) {
        this.formData.id = '';
        var params = {factoryCode: e.value}
        dsu.getAxiosCheckListInfo(params).then(res => {
          this.checkListInfo = res.data.data
          if(this.noUsedParameter){
            this.formData.id = this.$route.query.id;
          }
        });
      },
      onValueChangedCheckListId(e) {
        this.formData.cat1 = '';
        this.cat1List = [];

        if(e.value){
          let params = {id: e.value};

          dsu.getAxiosCheckListInfo(params).then(res => {
              this.catNames = res.data.data[0];
          });
          
          dsu.getAxiosCheckListCatInfo(params).then(res => {
              this.cat1List = res.data.data;
              if(this.noUsedParameter){
                this.formData.cat1 = this.$route.query.cat1;
              }
          });
        }
      },
      onValueChangedCat1(e){
        this.formData.cat2 = '';
        this.cat2List = [];
        
        if(e.value){
          let params = {id: this.formData.id,
                        parentCategory: e.value};

          dsu.getAxiosCheckListCat2Info(params).then(res => {
              this.cat2List = res.data.data;
              if(this.noUsedParameter){
                this.formData.cat2 = this.$route.query.cat2;
              }
          });
        }
      },
      onValueChangedCat2(){
        this.formData.cat3 = '';
        if(this.noUsedParameter){
            this.formData.cat3 = this.$route.query.cat3;
        }
      },
      loadCheckList(){
        const searchForm = this.$refs[this.searchFormRefName].instance;
    
        let validationResult =  searchForm.validate();
        if( validationResult.status != 'valid'){
            return;
        }

        Object.assign(this.selectedCheckListData, this.formData);
        this.selectedCheckListData.regDate = dsu.getFormatDate(this.selectedCheckListData.regDate);
      },
      resetData(){
        this.$refs[this.checkListDataRefName].clear();
      }
    }   
  };
</script>

<style scoped> 

</style>