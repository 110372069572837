<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formDataSearch"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />
      <DxSimpleItem
        data-field="location"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.locationInfo,
          valueExpr: 'ID',
          displayExpr: 'Name',
          onValueChanged: onValueChangedLocation,
        }"
      >
        <DxLabel text="Location" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="startDate"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',
        }"
      >
        <DxLabel text="Start Date" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="endDate"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',
        }"
      >
        <DxLabel text="End Date" />
        <DxRequiredRule />
      </DxSimpleItem>
      <DxButtonItem
        horizontal-alignment="center"
        vertical-alignment="center"
        :buttonOptions="{
          text: 'Search',
          icon: 'search',
          onClick: searchData,
        }"
      />
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      key-expr="num"
      @focused-row-changed="selectedRowChanged($event)"
      @cell-prepared="onCellPrepared"
      @exporting="onExporting"
      @rowDblClick="onDblClick"
      @toolbar-preparing="onToolbarPreparing"
    >
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multi" />
      <DxExport :enabled="true" />
      <DxSearchPanel :visible="true" location="before" :width="500" />

      <DxColumn
        data-field="num"
        caption="Num"
        alignment="center"
        :visiable="false"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="dt"
        caption="Date"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="category1"
        caption="Category 1"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="category2"
        caption="Category 2"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="currency"
        caption="Currency"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="bankAccount"
        caption="Bank Account"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="acctCode"
        caption="Account"
        :header-filter="{ allowSearch: true }"
      >
        <DxLookup
          :data-source="accountList"
          value-expr="acctCode"
          display-expr="acctCodeName"
        />
      </DxColumn>

      <DxColumn
        data-field="details"
        caption="Details"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="businessPartner"
        caption="Business Partner"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="deposit"
        caption="Deposit"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="withdraw"
        caption="Withdraw"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="depositKrw"
        caption="Deposit(KRW)"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="withdrawKrw"
        caption="Withdraw(KRW)"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="bondDebtStatus"
        caption="Approval"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="bondDebtBalance"
        caption="Balance"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="remark"
        caption="Remark"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="close"
        caption="Close"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="userId"
        caption="Update User"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="transTime"
        caption="Update Time"
        :header-filter="{ allowSearch: true }"
      />
      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <History
          :master-detail-data="data"
          :url="`/api/URFL/selectFundFlowDataHst`"
          :keyName="num"
          :columns="historyColumns"
        />
      </template>    

      <DxToolbar>
        <DxItem location="after">
          <template #default>
            <DxButton @click="showEditForm('I')" icon="add" text="NEW" />
          </template>
        </DxItem>
        
        <DxItem location="after">
          <template #default>
            <DxButton @click="showEditForm('C')" icon="copy" text="COPY NEW" />
          </template>
        </DxItem>

        <DxItem location="after">
          <template #default>
            <DxButton @click="showEditForm('U')" icon="edit" text="MODIFY" />
          </template>
        </DxItem>

        <DxItem location="after">
          <template #default>
            <DxButton @click="deleteData" icon="remove" text="DELETE" />
          </template>
        </DxItem>
        <DxItem location="after">
          <template #default>
            <DxButton
              @click="exportToExcel"
              icon="exportxlsx"
              text="DOWNLOAD"
            />
          </template>
        </DxItem>

         <DxItem location="after">
          <template #default>
            <DxButton
              @click="reSummary"
              icon="pulldown"
              text="RE-SUMMARY"
            />
          </template>
        </DxItem>

        <DxItem name="searchPanel" width="350" />
      </DxToolbar>
    </DxDataGrid>

    <DxPopup
      :ref="popupRefName"
      :drag-enabled="true"
      :close-on-outside-click="true"
      :title="popupTitle"
      width="800"
      height="auto">
      <DxForm :ref="formRefName" :formData="formData" :colCount="2">
        <DxSimpleItem
          data-field="dt"
          editor-type="dxDateBox"
          :editor-options="{ displayFormat: 'yyyy/MM/dd' }">
          <DxLabel text="Date" />
          <DxRequiredRule />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="category1"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: category1List,
            onValueChanged: onValueChangedCategory1,
            valueExpr: 'ID',
            displayExpr: 'Name',
            searchEnabled: true,
          }">
          <DxLabel text="Category 1" />
          <DxRequiredRule />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="category2"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: category2List,
            valueExpr: 'ID',
            displayExpr: 'Name',
            searchEnabled: true,
          }">
          <DxLabel text="Category 2" />
          <DxRequiredRule />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="currency"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: currencyList,
            valueExpr: 'ID',
            displayExpr: 'Name',
            searchEnabled: true,
          }">
          <DxLabel text="Currency" />
          <DxRequiredRule />
        </DxSimpleItem>

        <DxSimpleItem data-field="bankAccount">
          <template #default>
            <DxLabel text="Bank Account" />    
            <DxSelectBox
              :dataSource= bankAccountList
              displayExpr= "bankAccount"
              valueExpr= "bankAccount"
              :value="formData.bankAccount"
              :acceptCustomValue= true
              :searchEnabled= true
              :maxLength= "15"
              :showClearButton= true
              :validation-status="vsBankAccount"
              @customItemCreating= "customItemCreatingBankAccount($event)"
              @valueChanged= "onValueChangedBankAccount($event)"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem
          data-field="acctCode"
          editor-type="dxSelectBox"
          :editor-options="{
            dataSource: accountList,
            valueExpr: 'acctCode',
            displayExpr: 'acctCodeName',
            searchEnabled: true,
          }"
        >
          <DxLabel text="Account Code" />
          <DxRequiredRule />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="details"
          editor-type="dxTextBox"
          :colSpan="2"
          :editor-options="{ maxLength: 50 }"
        >
          <DxLabel text="Details" />
          <DxRequiredRule />
        </DxSimpleItem>

        <DxSimpleItem data-field="businessPartner" :colSpan="2">
          <template #default>
            <DxLabel text="Business Partner" />  
            <DxRequiredRule />      
            <DxSelectBox
              :dataSource= businessPartnerList
              displayExpr= "businessPartner"
              valueExpr= "businessPartner"
              :value="formData.businessPartner"
              :acceptCustomValue= true
              :searchEnabled= true
              :maxLength= "50"
              :validation-status="vsBusinessPartner"
              @customItemCreating= "customItemCreatingBusinessPartner($event)"
              @valueChanged= "onValueChangedBusinessPartner($event)"/>
          </template>
        </DxSimpleItem>

        <DxSimpleItem
          data-field="deposit"
          editor-type="dxNumberBox"
          :editor-options="{
            format: '###,###,###,##0.00',
          }"
        >
          <DxLabel text="Deposit" />
        </DxSimpleItem>

        <DxSimpleItem
          data-field="withdraw"
          editor-type="dxNumberBox"
          :editor-options="{
            format: '###,###,###,##0.00',
          }"
        >
          <DxLabel text="Withdraw" />
        </DxSimpleItem>
        <DxSimpleItem
          data-field="remark"
          editor-type="dxTextBox"
          :colSpan="2"
          :editor-options="{ maxLength: 50 }"
        >
          <DxLabel text="Remark" />
        </DxSimpleItem>

        <DxEmptyItem />

        <DxGroupItem :colCount="2">
          <DxButtonItem
            horizontal-alignment="right"
            vertical-alignment="center"
            :buttonOptions="{
              text: 'Save',
              icon: 'save',
              onClick: save,
            }"
          />
          <DxButtonItem
            horizontal-alignment="center"
            vertical-alignment="center"
            :buttonOptions="{
              text: 'Cancel',
              icon: 'close',
              onClick: cancel,
            }"
          />
        </DxGroupItem>
      </DxForm>
    </DxPopup>

    <DxPopup
      :ref="popupRefNameBondDebt"
      :drag-enabled="true"
      :close-on-outside-click="true"
      :title="popupTitlBondDebt"
      width="99%"
      height="auto"
    >
      <div class="content-block dx-card responsive-paddings">
        <DxDataGrid
          :ref="gridRefNameBondDebt"
          :data-source="dataSourceBondDebt"
          :column-auto-width="true"
          :show-borders="true"
          :show-column-lines="true"
          :focused-row-enabled="true"
          key-expr="num"
        >
          <DxScrolling mode="infinite" />

          <DxColumn
            data-field="num"
            caption="Num"
            alignment="center"
            :visiable="false"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="dt"
            caption="Date"
            alignment="center"
            width="115"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="category1"
            caption="Category 1"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="category2"
            caption="Category 2"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="currency"
            caption="Currency"
            alignment="center"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="bankAccount"
            caption="Bank Account"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="acctCode"
            caption="Account"
            :header-filter="{ allowSearch: true }"
          >
            <DxLookup
              :data-source="accountList"
              value-expr="acctCode"
              display-expr="acctCodeName"
            />
          </DxColumn>

          <DxColumn
            data-field="details"
            caption="Details"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="businessPartner"
            caption="Business Partner"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="deposit"
            caption="Deposit"
            dataType="number"
            format="###,###,###,##0.00"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="withdraw"
            caption="Withdraw"
            dataType="number"
            format="###,###,###,##0.00"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="depositKrw"
            caption="Deposit(KRW)"
            dataType="number"
            format="###,###,###,##0.00"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="withdrawKrw"
            caption="Withdraw(KRW)"
            dataType="number"
            format="###,###,###,##0.00"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn caption="Bond/Debt">
            <DxColumn
              data-field="bondDebtStatus"
              caption="Approval"
              :header-filter="{ allowSearch: true }"
            />
            <DxColumn
              data-field="bondDebtBalance"
              caption="Balance"
              dataType="number"
              format="###,###,###,##0.00"
              :header-filter="{ allowSearch: true }"
            />
          </DxColumn>

          <DxColumn
            data-field="remark"
            caption="Remark"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="close"
            caption="Close"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="userId"
            caption="Update User"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="transTime"
            caption="Update Time"
            :header-filter="{ allowSearch: true }"
          />
        </DxDataGrid>
      </div>

      <div class="content-block dx-card responsive-paddings">
        <DxForm
          :ref="formRefNameBondDebt"
          :formData="formDataBondDebt"
          :colCount="2"
        >
          <DxSimpleItem
            data-field="dt"
            editor-type="dxDateBox"
            :editor-options="{ displayFormat: 'yyyy/MM/dd' }"
          >
            <DxLabel text="Date" />
            <DxRequiredRule />
          </DxSimpleItem>

          <DxSimpleItem data-field="bankAccount">
            <template #default>
              <DxLabel text="Bank Account" />  
              <DxRequiredRule />      
              <DxSelectBox
                :dataSource= bankAccountList
                displayExpr= "bankAccount"
                valueExpr= "bankAccount"
                :value="formDataBondDebt.bankAccount"
                :acceptCustomValue= true
                :searchEnabled= true
                :maxLength= "15"
                :validation-status="vsBankAccount"
                @customItemCreating= "customItemCreatingBankAccount($event)"
                @valueChanged= "onValueChangedBankAccountBondDebt($event)"/>
            </template>
          </DxSimpleItem>

          <DxGroupItem :colCount="2">
            <DxSimpleItem
              data-field="amountBondDebt1"
              editor-type="dxNumberBox"
              :editor-options="{
                format: '###,###,###,##0.00',
                valueChangeEvent: 'keyup',
              }"
            >
              <DxLabel :text="amountBondDebt1Name" />
              <DxRequiredRule />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="balance"
              editor-type="dxNumberBox"
              :editor-options="{
                format: '###,###,###,##0.00',
                disabled: true,
              }"
            >
              <DxLabel text="Balance" />
              <DxRequiredRule />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem :colCount="2">
            <DxSimpleItem
              data-field="fee"
              editor-type="dxNumberBox"
              :editor-options="{
                format: '###,###,###,##0.00',
              }"
            >
              <DxLabel text="Fee" />
            </DxSimpleItem>
          </DxGroupItem>

          <DxSimpleItem
            data-field="remark"
            editor-type="dxTextBox"
            :editor-options="{ maxLength: 50 }"
          >
            <DxLabel text="Remark" />
          </DxSimpleItem>

          <DxEmptyItem />
          <DxEmptyItem />

          <DxGroupItem :colCount="2">
            <DxButtonItem
              horizontal-alignment="right"
              vertical-alignment="center"
              :buttonOptions="{
                text: 'Save',
                icon: 'save',
                onClick: saveBondDebt,
              }"
            />
            <DxButtonItem
              horizontal-alignment="center"
              vertical-alignment="center"
              :buttonOptions="{
                text: 'Cancel',
                icon: 'close',
                onClick: cancelBondDebt,
              }"
            />
          </DxGroupItem>
        </DxForm>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxButtonItem,
  DxGroupItem,
  DxEmptyItem
} from "devextreme-vue/form";
import {DxSelectBox} from "devextreme-vue/select-box";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxRequiredRule,
  DxToolbar,
  DxItem,
  DxLookup,
  DxExport,
  DxSearchPanel,
  DxMasterDetail
} from "devextreme-vue/data-grid";
import { locationFundFlow } from "../../utils/syscodedata";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import store from "../../store";
import DxPopup from "devextreme-vue/popup";
import DxButton from "devextreme-vue/button";
import DataSource from 'devextreme/data/data_source';
import History from "../_common/SIMPLE_HISTORY";
var endDate = new Date();
var startDate = new Date();
startDate.setDate(endDate.getDate() - 30);
var defaultStartDate = startDate;
var defaultEndDate = endDate;

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxRequiredRule,
    DxToolbar,
    DxButtonItem,
    DxPopup,
    DxItem,
    DxButton,
    DxGroupItem,
    DxEmptyItem,
    DxLookup,
    DxExport,
    DxSearchPanel,
    DxSelectBox,
    History,
    DxMasterDetail
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      formDataSearch: { startDate: defaultStartDate, endDate: defaultEndDate },
      formRefName: "form",
      formData: {},
      popupRefName: "popup",
      popupTitle: "",
      businessPartnerList: [],
      businessPartnerListDatatSource: null,
      bankAccountList: [],
      bankAccountDatatSource: null,
      selectedRow: {},
      popupRefNameBondDebt: "popupBondDebt",
      popupTitlBondDebt: "",
      formRefNameBondDebt: "formBondDebt",
      formDataBondDebt: {},
      gridRefName: "gridFundData",
      dataSource: [],
      gridRefNameBondDebt: "gridBondDebt",
      dataSourceBondDebt: [],
      locationInfo: locationFundFlow,
      accountList: [],
      currencyList: [],
      vsBusinessPartner: "valid",
      vsBankAccount: "valid",
      selectedBondDebtType: "",
      amountBondDebt1Name: "",
      amountBondDebt2Name: "",
      category1List: [
        { ID: "자금", Name: "자금" },
        { ID: "채권채무", Name: "채권채무" },
      ],
      category2List: [],
      category2_1List: [
        { ID: "현금", Name: "현금" },
        { ID: "예금", Name: "예금" },
        { ID: "계획", Name: "계획" },
        { ID: "대여", Name: "대여" },
        { ID: "예적금", Name: "예적금" },
      ],
      category2_2List: [
        { ID: "채권", Name: "채권" },
        { ID: "채무", Name: "채무" },
      ],
      historyColumns: [
        { dataField: "transTime", caption: "Update Time" },
        { dataField: "userId", caption: "Name" },
        { dataField: "num", caption: "Num" },
        { dataField: "dt", caption: "Date" },
        { dataField: "category1", caption: "Category 1" },
        { dataField: "category2", caption: "Category 2" },
        { dataField: "currency", caption: "Currency" },
        { dataField: "bankAccount", caption: "Bank Account" },
        { dataField: "acctCode", caption: "Account" },
        { dataField: "details", caption: "Details" },
        { dataField: "businessPartner", caption: "Business Partner" },
        { dataField: "deposit", caption: "Deposit" },
        { dataField: "withdraw", caption: "Withdraw" },
        { dataField: "depositKrw", caption: "Deposit(KRW)" },
        { dataField: "withdrawKrw", caption: "Withdraw(KRW)" },
        { dataField: "bondDebtStatus", caption: "Approval" },
        { dataField: "bondDebtBalance", caption: "Balance" },
        { dataField: "remark", caption: "Remark" },
        { dataField: "dml", caption: "Action" }]
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    popup: function () {
      return this.$refs[this.popupRefName].instance;
    },
    searchForm: function () {
      return this.$refs[this.searchFormRefName].instance;
    },
    form: function () {
      return this.$refs[this.formRefName].instance;
    },
    gridBondDebt: function () {
      return this.$refs[this.gridRefNameBondDebt].instance;
    },
    popupBondDebt: function () {
      return this.$refs[this.popupRefNameBondDebt].instance;
    },
    formBondDebt: function () {
      return this.$refs[this.formRefNameBondDebt].instance;
    },
  },
  watch: {
    "formDataBondDebt.amountBondDebt1"(newValue) {
      this.formDataBondDebt.balance =
        this.selectedRow.bondDebtBalance - newValue;
      this.formBondDebt.option("formData", this.formDataBondDebt);
    },
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "URFL"
    );
    const myLocation = store.getters.getLocation;
    if (menuAuth.f1Auth == "false") {
      this.locationInfo = [{ ID: myLocation, Name: myLocation }];
    }
    this.formDataSearch.location = this.locationInfo[0].ID;
    dsu.getAxiosAccountList().then((res) => {
      this.accountList = res.data.data;
    });
    setTimeout(() => {
      this.searchData();
    }, 1000);
  },
  methods: {
    onToolbarPreparing: function (e) {
      let toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach(function (item) {
        if (item.name === "searchPanel") {
          item.location = "before";
        }
      });  
    },
    clearGrid() {
      this.dataSource = [];
      this.grid.refresh();
    },
    onDblClick() {
      this.showBondDebtForm();
    },
    reSummary(e) {
      e.component.option("disabled", true);
      this.grid.beginCustomLoading("Data Summarizing...");
      var params = {};
      params.location = this.formDataSearch.location;

      dsu.getAxiosOBJGet("/doSummaryFundFlow", params).then((res) => {
        let response = res.data;
        e.component.option("disabled", false);
        this.grid.endCustomLoading();
        if (response.result == "SUCCESS") {
          fn.notifySuccess(response.msg);
          this.searchData();
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    showBondDebtForm() {
      this.vsBankAccount = "valid";
      this.refreshBankAccount();
      this.selectedRow = this.dataSource.find(
        (item) => item.num == this.selectedRow.num
      );

      if (this.selectedRow.category1 == "채권채무") {
        this.dataSourceBondDebt = [];
        this.formDataBondDebt = {};
        this.formDataBondDebt.dt = defaultEndDate;
        this.formDataBondDebt.amountBondDebt1 = Number(
          this.selectedRow.bondDebtBalance
        );
        this.formDataBondDebt.fee = null;
        this.formDataBondDebt.amountBondDebt2 = null;

        if (this.selectedRow.category2 == "채권") {
          this.popupTitlBondDebt = "Bond Info";
          this.selectedBondDebtType = "BOND";
          this.amountBondDebt1Name = "Deposit 자금-채권";
        } else if (this.selectedRow.category2 == "채무") {
          this.popupTitlBondDebt = "Debt Info";
          this.selectedBondDebtType = "DEBT";
          this.amountBondDebt1Name = "Withdraw 자금-채무";
        } else {
          return;
        }

        this.popupBondDebt.show();
        this.formBondDebt.resetValues();
        this.formBondDebt.option("formData", this.formDataBondDebt);

        var params = {};
        params.location = this.formDataSearch.location;
        params.details = this.selectedRow.details;
        params.businessPartner = this.selectedRow.businessPartner;
        params.currency = this.selectedRow.currency;

        params.bondDebt = this.selectedBondDebtType;
        this.gridBondDebt.beginCustomLoading("Data Loading...");
        dsu
          .getAxiosOBJGet("/api/URFL/selectFundFlowData", params)
          .then((res) => {
            let response = res.data;
            this.gridBondDebt.endCustomLoading();
            if (res.data.result == "SUCCESS") {
              this.dataSourceBondDebt = response.data;
            } else {
              fn.notifyError(response.msg);
            }
          });
      }
    },
    showEditForm(DML) {
      this.vsBusinessPartner = "valid";
      this.vsBankAccount = "valid";
      this.form.resetValues();
      var selecteData = this.dataSource.find(
          (item) => item.num === this.selectedRow.num
        );
      if (DML == "I") {
        this.popupTitle = "NEW DATA";
        this.formData = {};
        this.formData.dt = defaultEndDate;
        if (this.currencyList.length == 1) {
          this.formData.currency = this.currencyList[0].ID;
        }
        this.category2List = [];
      } else if (DML == "C") {
        if (this.selectedRow.num == undefined) {
          //fn.notifyError("복사 대상 선택 후 진행 필요");
          fn.notifyError("Need to proceed after selecting the copy destination.");
          return;
        }
        this.form.resetValues();
        this.popupTitle = "COPY DATA";
        this.formData = JSON.parse(JSON.stringify(selecteData));
        this.formData.num = '';
        this.formData.dt = new Date();
      } else if (DML == "U") {
        if (this.selectedRow.num == undefined) {
          //fn.notifyError("수정 대상 선택 후 진행 필요");
          fn.notifyError("Need to proceed after selecting the target for modification.");
          return;
        }
        this.form.resetValues();
        this.popupTitle = "MODIFY DATA";
        this.formData = JSON.parse(JSON.stringify(selecteData));
      }

      this.businessPartnerList = [];
      var params = {};
      params.location = this.formDataSearch.location;
      dsu
        .getAxiosOBJGet("/api/URFL/selectBusinessPartner", params)
        .then((res) => {
          let response = res.data;
          if (res.data.result == "SUCCESS") {

            this.businessPartnerList = response.data;
            this.businessPartnerListDataSource  = new DataSource({
              store: {
                data:  this.businessPartnerList,
                type: 'array',
                key: 'businessPartner',
              },
            });
          } else {
            fn.notifyError(response.msg);
          }
        });

        this.refreshBankAccount();
        this.popup.show();
    },
    selectedRowChanged(e) {
      this.selectedRow = JSON.parse(JSON.stringify(e.row.data));
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    },
    refreshBankAccount() {   
      this.bankAccountList = [];
      var params = {};
      params.location = this.formDataSearch.location;
      dsu
        .getAxiosOBJGet("/api/URFL/selectBankAccount", params)
        .then((res) => {
          let response = res.data;
          if (res.data.result == "SUCCESS") {

            this.bankAccountList = response.data;
            this.bankAccountListDataSource  = new DataSource({
              store: {
                data:  this.bankAccountList,
                type: 'array',
                key: 'bankAccount',
              },
            });

            
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    searchData() {
      this.clearGrid();
      var params = {};
      Object.assign(params, this.formDataSearch);
      params.startDate = dsu.getFormatDate(params.startDate);
      params.endDate = dsu.getFormatDate(params.endDate);
      let validationResult = this.searchForm.validate();
      if (validationResult.status != "valid") {
        return;
      }
      this.grid.beginCustomLoading("Data Loading...");

      dsu.getAxiosOBJGet("/api/URFL/selectFundFlowData", params).then((res) => {
        let response = res.data;
        this.grid.endCustomLoading();
        
        if (res.data.result == "SUCCESS") {
          this.dataSource = response.data;
 
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    onValueChangedBusinessPartner(e) {
      this.formData.businessPartner = e.value;
      this.vsBusinessPartner = "valid";  
    },
    onValueChangedBankAccount(e) {
      this.formData.bankAccount = e.value;
      this.vsBankAccount = "valid";  
    },
    onValueChangedBankAccountBondDebt(e) {
      this.formDataBondDebt.bankAccount = e.value;
      this.vsBankAccount = "valid";  
    },
    onValueChangedCategory1(e) {
      this.vsBankAccount = "valid";
      if (e.value == "자금") {
        this.category2List = this.category2_1List;
      } else if (e.value == "채권채무") {
        this.category2List = this.category2_2List;
      }

      if (
        !this.category2List.some((item) => item.ID === this.formData.category2)
      ) {
        this.formData.category2 = "";
        this.form.option("formData", this.formData);
      }
    },
    save(e) {      
      let validationResult = this.form.validate();
      
      if (!this.formData.businessPartner) {
        this.vsBusinessPartner = "invalid";
      }

      if (!this.formData.bankAccount) {
        if (this.formData.category1 == "자금") {
          this.vsBankAccount = "invalid";
        }
      }
      
      if (validationResult.status != "valid" || this.vsBusinessPartner != "valid" || this.vsBankAccount != "valid") {
        return;
      }

      if (!this.formDataSearch.location) {
        //fn.notifyError("Location 값 입력 필요");
        fn.notifyError("Location value input required");
        return;
      }

      if (
        this.formData.category1 == "채권채무" &&
        this.formData.category2 == "채권"
      ) {
        if (!this.formData.deposit || this.formData.withdraw) {
          // fn.notifyError("채권채무-채권 건은 Deposit 값만 필수 입력");
          fn.notifyError("Case 채권채무-채권, only the deposit amount is required.");
          return;
        }
      } else if (
        this.formData.category1 == "채권채무" &&
        this.formData.category2 == "채무"
      ) {
        if (!this.formData.withdraw || this.formData.deposit) {
          //fn.notifyError("채권채무-채무 건은 Withdraw 값만 필수 입력");
          fn.notifyError("Case 채권채무-채무, only the Withdraw amount is required.");
          return;
        }
      }

      if (!this.formData.deposit && !this.formData.withdraw) {
        //fn.notifyError("Deposit 또는 Withdraw 값 입력 필요");
        fn.notifyError("Requires input of Deposit or Withdraw value");
        return;
      }

      if (this.formData.deposit && this.formData.withdraw) {
        //fn.notifyError("Deposit 또는 Withdraw 값 중 하나만 입력 가능");
        fn.notifyError("Only one of Deposit or Withdraw values can be entered.");
        return;
      }

      if (this.formData.category1 == "자금") {
        if (!this.formData.bankAccount) {
          // fn.notifyError("Bank Account 값 입력 필요");
          fn.notifyError("Bank Account value required");
          return;
        }
      }

      var params = {};
      Object.assign(params, this.formData);
      params.dt = dsu.getFormatDate(params.dt);
      params.location = this.formDataSearch.location;

      if (params.deposit == 0) {
        params.deposit = null;
      }

      if (params.withdraw == 0) {
        params.withdraw = null;
      }

      e.component.option("disabled", true);
      dsu
        .getAxiosOBJPost("/api/URFL/updateFundFlowData", params)
        .then((res) => {
          let rtnMsg = res.data.msg;
          if (res.data.result == "SUCCESS") {
            fn.notifySuccess(rtnMsg);
            this.popup.hide();
            this.form.resetValues();
            this.searchData();
          } else {
            fn.notifyError(rtnMsg);
          }
          e.component.option("disabled", false);
        });
    },
    cancel() {
      this.popup.hide();
    },
    cancelBondDebt() {
      this.popupBondDebt.hide();
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    saveBondDebt(e) {
   
      let validationResult = this.formBondDebt.validate();

      if (!this.formDataBondDebt.bankAccount) {
        this.vsBankAccount = "invalid";
      }
      
      if (validationResult.status != "valid" || this.vsBankAccount != "valid") {
        return;
      }

      // if (!this.selectedRow.bondDebtStatus) {
      //   // fn.notifyError("결재완료 건은 수정 불가");
      //   fn.notifyError("Payment completed cannot be modified");
      //   return;
      // }
      
      // if (
      //   dsu.getFormatDate(this.selectedRow.dt) >
      //   dsu.getFormatDate(this.formDataBondDebt.dt)
      // ) {
      //   if (this.selectedBondDebtType == "BOND") {
      //     //fn.notifyError("채권 발생일 보다 이전 날짜로 입력 불가능");
      //     fn.notifyError("You cannot enter a date earlier than the bond issuance date.");
      //   } else if (this.selectedBondDebtType == "DEBT") {
      //     //fn.notifyError("채무 발생일 보다 이전 날짜로 입력 불가능");
      //     fn.notifyError("You cannot enter a date earlier than the debt issuance date.");
      //   }
      //   return;
      // }

      // if (this.formDataBondDebt.balance < 0) {
      //   //fn.notifyError("입력 금액이 잔액보다 큽니다.");
      //   fn.notifyError("The amount entered is greater than the balance.");
      //   return;
      // }

      var params = {};
      Object.assign(params, this.formDataBondDebt);
      params.location = this.selectedRow.location;
      params.dt = dsu.getFormatDate(params.dt);
      params.details = this.selectedRow.details;
      params.businessPartner = this.selectedRow.businessPartner;
      params.acctCode = this.selectedRow.acctCode;
      params.currency = this.selectedRow.currency;
      params.type = this.selectedBondDebtType;

      e.component.option("disabled", true);
      dsu
        .getAxiosOBJPost("/api/URFL/updateFundFlowDataBondDebt", params)
        .then((res) => {
          let rtnMsg = res.data.msg;
          if (res.data.result == "SUCCESS") {
            fn.notifySuccess(rtnMsg);
            this.popupBondDebt.hide();
            this.searchData();
          } else {
            fn.notifyError(rtnMsg);
          }
          e.component.option("disabled", false);
        });
    },
    async deleteData(e) {
      // if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
     if ((await fn.confirm("Are you sure you want to delete?")) == false) {
        return;
      } else {
        if (!this.selectedRow.num) {
          // fn.notifyError("삭제 대상 선택 후 진행 필요");
          fn.notifyError("Requires selection of deletion target before proceeding");
          return;
        }

        if (!this.formDataSearch.location) {
          //fn.notifyError("Location 선택 후 진행 필요");
          fn.notifyError("Need to proceed after selecting location");
          return;
        }

        var params = {};
        Object.assign(params, this.selectedRow);
        params.location = this.formDataSearch.location;
        params.dt = dsu.getFormatDate(params.dt);

        e.component.option("disabled", true);
        dsu
          .getAxiosOBJPost("/api/URFL/deleteFundFlowData", params)
          .then((res) => {
            let rtnMsg = res.data.msg;
            if (res.data.result == "SUCCESS") {
              fn.notifySuccess(rtnMsg);
              this.form.resetValues();
              this.selectedRow = {};
              this.searchData();
            } else {
              fn.notifyError(rtnMsg);
            }
            e.component.option("disabled", false);
          });
      }
    },
    onCellPrepared(e) {
      if (e.rowType == "header") {
        e.cellElement.bgColor = "#E4EEF7";
      } else if (e.rowType == "data") {
        if (e.data.category1 == "채권채무") {
          if (e.data.bondDebtStatus == "미결") {
            if (e.data.category2 == "채권") {
              e.cellElement.bgColor = "#fcfcc7";
            } else if (e.data.category2 == "채무") {
              e.cellElement.bgColor = "#fadede";
            }
          }
        }
      }
    },
    customItemCreatingBusinessPartner(data) {
      if (!data.text) {
        data.customItem = null;
        return;
      }
      
      const newItem = {
        businessPartner: data.text,
      };

      data.customItem = this.businessPartnerListDataSource
        .store()
        .insert(newItem)
        .then(() => this.businessPartnerListDataSource.load())
        .then(() => newItem)
        .catch((error) => {
          throw error;
        });
    },
    customItemCreatingBankAccount(data) {
      if (!data.text) {
        data.customItem = null;
        return;
      }
      
      const newItem = {
        bankAccount: data.text,
      };

      data.customItem = this.bankAccountListDataSource
        .store()
        .insert(newItem)
        .then(() => this.bankAccountListDataSource.load())
        .then(() => newItem)
        .catch((error) => {
          throw error;
        });
    },
    onValueChangedLocation(e) {
      this.clearGrid();
      if (e.value == "USA") {
        this.currencyList = [{ ID: "USD", Name: "USD" }];
      } else if (e.value == "EU") {
        this.currencyList = [
          { ID: "EUR", Name: "EUR" },
          { ID: "USD", Name: "USD" },
          { ID: "HUF", Name: "HUF" },
        ];
      } else if (e.value == "HK") {
        this.currencyList = [
          { ID: "HKD", Name: "HKD" },
          { ID: "USD", Name: "USD" },
          { ID: "EUR", Name: "EUR" },
        ];
      } else if (e.value == "KOR") {
        this.currencyList = [
          { ID: "KRW", Name: "KRW" },
          { ID: "USD", Name: "USD" },
          { ID: "CNY", Name: "CNY" },
          { ID: "EUR", Name: "EUR" },
          { ID: "JPY", Name: "JPY" },
        ];
      } else if (e.value == "TH") {
        this.currencyList = [
          { ID: "THB", Name: "THB" },
          { ID: "MMK", Name: "MMK" },
          { ID: "USD", Name: "USD" },
        ];
      } else if (e.value == "WD") {
        this.currencyList = [
          { ID: "CNY", Name: "CNY" },
          { ID: "USD", Name: "USD" },
        ];
      } else if (e.value == "ZQ") {
        this.currencyList = [
          { ID: "CNY", Name: "CNY" },
          { ID: "USD", Name: "USD" },
        ];
      }
       
    },
  },
};
</script>
<style scoped>
#grid {
  height: calc(100vh - 250px);
}
.buttonContainer {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}

.center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
